import http from '@/http.js';

export class LicenseService {
  static getLicenses() {
    return http.get('/api/licenses');
  }

  static getLicenseDetails(id) {
    return http.get(`/api/licenses/${id}`);
  }

  static createLicense(data) {
    return http.post('/api/licenses', JSON.stringify(data));
  }

  static updateLicense(id, data) {
    return http.put(`/api/licenses/${id}`, JSON.stringify(data));
  }

  static deleteLicense(id) {
    return http.delete(`/api/licenses/${id}`);
  }
}
