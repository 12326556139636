import http from '@/http.js';

export class QRCodeService {
  static getQRCodes() {
    return http.get('/api/qr-codes');
  }

  static getQRCodeDetails(id) {
    return http.get(`/api/qr-codes/${id}`);
  }

  static createQRCode(data) {
    return http.post('/api/qr-codes', JSON.stringify(data));
  }

  static updateQRCode(id, data) {
    return http.put(`/api/qr-codes/${id}`, JSON.stringify(data));
  }

  static deleteQRCode(id) {
    return http.delete(`/api/qr-codes/${id}`);
  }

  static sendEmailQRCode(data) {
    return http.post('/api/qr-codes/mail-qr-code', JSON.stringify(data))
  }

  static getQRCodesLeft() {
    return http.get('/api/qr-codes/qr-codes-left');
  }

  static getTowns() {
    return http.get('/api/qr-codes/get-towns');
  }
}
