import { QRCodeService } from '@/services/qr_codes.service.js';

const state = {
  qrcodes: null,
  selectedQRCode: null,
  error: null,
  success: null
};

const getters = {
  qrcodes: state => state.qrcodes,
  selectedQRCode: state => state.selectedQRCode,
  qrcodeError: state => state.error,
  qrcodeSuccess: state => state.success
};

const actions = {
  async fetchQRCodes({ commit }) {
    try {
      const response = await QRCodeService.getQRCodes();
      commit('SET_QRCODES', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching qr codes');
    }
  },
  async createQRCode({ commit }, data) {
    try {
      const response = await QRCodeService.createQRCode(data);
      commit('SET_SUCCESS', 'QR code created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating qr code');
    }
  },
  async updateQRCode({ commit }, { id, data }) {
    try {
      const response = await QRCodeService.updateQRCode(id, data);
      commit('SET_SUCCESS', 'QR code updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating qr code');
    }
  },
  async deleteQRCode({ commit }, id) {
    try {
      await QRCodeService.deleteQRCode(id);
      commit('SET_SUCCESS', 'QR code deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting qr code');
    }
  },
  async sendEmailQRCode({ commit }, data) {
    return await QRCodeService.sendEmailQRCode(data).then(response => {
      if (typeof response.response === "undefined") {
        commit('SET_SUCCESS', 'Er is een e-mail verstuurd naar het opgegeven e-mailadres');
      } else {
        commit('SET_ERROR', 'Er is iets misgegaan');
      }
    })
  },
  async getQRCodesLeft() {
    return await QRCodeService.getQRCodesLeft().then(response => {
      return response.data;
    })
  },
  async getTowns() {
    return await QRCodeService.getTowns().then(response => {
      return response.data;
    })
  },

  setSelectedQRCode({ commit }, qrcode) {
    commit('SET_SELECTED_QRCODE', qrcode);
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_QRCODES: (state, qrcodes) => {
    state.qrcodes = qrcodes;
  },
  SET_SELECTED_QRCODE: (state, qrcode) => {
    state.selectedQRCode = qrcode;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_SUCCESS: (state, success) => {
    state.success = success;
  },
  clearQRCodes(state) {
    state.qrcodes = null;
    state.selectedQRCode = null;
    state.qrcodeError = null;
    state.qrcodeSuccess = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
