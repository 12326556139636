import http from '@/http.js'

export class AuthService {
  static login(data) {
    return http.post('/auth/login', JSON.stringify({
      email: data.email,
      password: data.password
    }))
  }

  static forgotPassword(data) {
    return http.post('/auth/password/reset/request', JSON.stringify({
      email: data.email
    }))
  }

  static resetPassword(data) {
    return http.post('/auth/password/reset', JSON.stringify({
      token: data.token,
      new_password: data.new_password
    }))
  }

  static getUser() {
    return http.get('/api/users/me')
  }
}
