import http from '@/http.js';

export class OrganisationService {
  static getOrganisations() {
    return http.get('/api/organisations');
  }

  static getOrganisationDetails(id) {
    return http.get(`/api/organisations/${id}`);
  }

  static createOrganisation(data) {
    return http.post('/api/organisations', JSON.stringify(data));
  }

  static updateOrganisation(id, data) {
    return http.put(`/api/organisations/${id}`, JSON.stringify(data));
  }

  static deleteOrganisation(id) {
    return http.delete(`/api/organisations/${id}`);
  }
}
