import { createApp } from 'vue'
import { encryptStorage } from '@/constants.js'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueQRCodeComponent from 'vue-qrcode-component'

import App from './App.vue'
import router from './router'
import store from './store'

import './assets/styles/index.css'
import './assets/js/scripts.js'

async function main () {
  if (encryptStorage.getItem('kindentaal-access-token') && !store.getters.user) {
    await store.dispatch('getUser')
  }
  createApp(App).use(store).use(router).component('font-awesome-icon', FontAwesomeIcon).component('qr-code', VueQRCodeComponent).mount('#app')
}

main()