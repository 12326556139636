import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

const routes = [
  {
    path: '/',
    alias: '/',
    meta: { login: true },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: () => import('@/views/QRCodes'),
        meta: { login: true }
      },
      {
        path: '/organisations',
        name: 'OrganisationList',
        component: () => import('@/views/organisation/OrganisationList'),
        meta: { login: true, groups: ['superadmin'] }
      },
      {
        path: '/organisations/:id/edit',
        name: 'EditOrganisation',
        component: () => import('@/views/organisation/EditOrganisation'),
        meta: { login: true, groups: ['superadmin'] }
      },
      {
        path: '/organisations/new',
        name: 'NewOrganisation',
        component: () => import('@/views/organisation/NewOrganisation'),
        meta: { login: true, groups: ['superadmin'] }
      },
      {
        path: '/users',
        name: 'UsersList',
        component: () => import('@/views/user/UserList'),
        meta: { login: true, groups: ['admin', 'superadmin'] }
      },
      {
        path: '/users/:id/edit',
        name: 'EditUser',
        component: () => import('@/views/user/EditUser'),
        meta: { login: true, groups: ['admin', 'superadmin'] }
      },
      {
        path: '/users/new',
        name: 'NewUser',
        component: () => import('@/views/user/NewUser'),
        meta: { login: true, groups: ['admin', 'superadmin'] }
      },
      {
        path: '/qr-codes',
        name: 'QRCodes',
        component: () => import('@/views/QRCodes'),
        meta: { login: true, groups: ['user', 'admin', 'superadmin'] }
      },
      {
        path: '/qr-code',
        name: 'QRCode',
        component: () => import('@/views/QRCode'),
        meta: { login: true, groups: ['user', 'admin', 'superadmin'] }
      },
      {
        path: '/licenses',
        name: 'Licenses',
        component: () => import('@/views/Licenses'),
        meta: { login: true, groups: ['admin', 'superadmin'] }
      },
      {
        path: '/analyse',
        name: 'Analyse',
        component: () => import('@/views/Analyse'),
        meta: { login: true, groups: ['superadmin'] }
      },
      {
        path: '/surveys',
        name: 'Surveys',
        component: () => import('@/views/Surveys'),
        meta: { login: false }
      },
      {
        path: '/Survey',
        name: 'Survey',
        component: () => import('@/views/Survey'),
        meta: { login: false }
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login'),
    meta: { login: false },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/auth/ForgotPassword'),
    meta: { login: false }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('@/views/auth/ResetPassword'),
    meta: { login: false }
  },
  {
    path: '/niet-geauthoriseerd',
    name: '403',
    component: () => import('@/views/NoAccess.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('@/views/NotFound.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta?.login === true && !store.getters.user) {
    return next('/login')
  }

  if (to.meta?.login === false && store.getters.user && !['Surveys', 'Survey'].includes(to.name)) {
    return next('/qr-codes')
  }
  
  if (to.meta?.groups && !to.meta.groups.includes(store.getters.user?.group)) {
    return next('/niet-geauthoriseerd')
  }

  next()
})

export default router