import { SurveyService } from '@/services/survey.service.js';

const state = {
  qrcode: null,
  surveys: null,
  selectedSurvey: null,
  currentQuestion: null,
  languages: null,
  error: null,
  success: null
};

const getters = {
  qrcode: state => state.qrcode,
  surveys: state => state.surveys,
  selectedSurvey: state => state.selectedSurvey,
  currentQuestion: state => state.currentQuestion,
  languages: state => state.languages,
  surveyError: state => state.error,
  surveySuccess: state => state.success
};

const actions = {
  async fetchQRCode({ commit }, data) {
    try {
      const response = await SurveyService.findQRCode(data);
      commit('SET_QRCODE', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching qr codes');
    }
  },
  async acceptTerms({ commit }, data) {
    try {
      const response = await SurveyService.acceptTerms(data);
      commit('SET_SUCCESS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while accepting terms');
    }
  },
  async fetchSurveys({ commit }, data) {
    try {
      const response = await SurveyService.getSurveys(data.entry, data);
      commit('SET_SURVEYS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching surveys');
    }
  },
  async setSelectedSurvey({ commit }, data) {
    try {
      commit('SET_SELECTED_SURVEY', data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while setting selected survey');
    }
  },
  async getNextQuestion({ commit }, data) {
    try {
      const response = await SurveyService.getNextQuestion(data.survey_id, data);
      commit('SET_QUESTION', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching next question');
    }
  },
  async getQuestionBySequence({ commit }, data) {
    try {
      const response = await SurveyService.getQuestionBySequence(data.survey_id, data);
      commit('SET_QUESTION', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching question by sequence');
    }
  },
  async setResponse({ commit }, data) {
    try {
      const response = await SurveyService.setResponse(data.survey_id, data);
      commit('SET_QUESTION', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while setting response');
    }
  },
  async clearSurvey({ commit }) {
    try {
      commit('SET_SELECTED_SURVEY', null);
      commit('SET_QUESTION', null);
      commit('SET_ERROR', null);
      commit('SET_SUCCESS', null);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while clearing survey');
    }
  },
  async getLanguages({ commit }) {
    try {
      const response = await SurveyService.getLanguages();
      commit('SET_LANGUAGES', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching languages');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_QRCODE: (state, qrcode) => {
    state.qrcode = qrcode;
  },
  SET_SURVEYS: (state, surveys) => {
    state.surveys = surveys;
  },
  SET_SELECTED_SURVEY: (state, survey) => {
    state.selectedSurvey = survey;
  },
  SET_QUESTION: (state, question) => {
    state.currentQuestion = question;
  },
  SET_LANGUAGES: (state, languages) => {
    state.languages = languages;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_SUCCESS: (state, success) => {
    state.success = success;
  },
  clearSurveys(state) {
    state.qrcode = null;
    state.surveys = null;
    state.selectedSurvey = null;
    state.currentQuestion = null;
    state.languages = null;
    state.surveyError = null;
    state.surveySuccess = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
