import { LicenseService } from '@/services/license.service.js';

const state = {
  licenses: null,
  selectedLicense: null,
  error: null,
  success: null
};

const getters = {
  licenses: state => state.licenses,
  selectedLicense: state => state.selectedLicense,
  licenseError: state => state.error,
  licenseSuccess: state => state.success
};

const actions = {
  async fetchLicenses({ commit }) {
    try {
      const response = await LicenseService.getLicenses();
      commit('SET_LICENSES', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching licenses');
    }
  },
  async createLicense({ commit }, data) {
    try {
      const response = await LicenseService.createLicense(data);
      commit('SET_SUCCESS', 'License created successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating license');
    }
  },
  async updateLicense({ commit }, { id, data }) {
    try {
      const response = await LicenseService.updateLicense(id, data);
      commit('SET_SUCCESS', 'License updated successfully');
      return response.data;
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating license');
    }
  },
  async deleteLicense({ commit }, id) {
    try {
      await LicenseService.deleteLicense(id);
      commit('SET_SUCCESS', 'License deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting license');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_LICENSES: (state, licenses) => {
    state.licenses = licenses;
  },
  SET_SELECTED_LICENSE: (state, license) => {
    state.selectedLicense = license;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_SUCCESS: (state, success) => {
    state.success = success;
  },
  clearLicenses(state) {
    state.licenses = null;
    state.selectedLicense = null;
    state.licenseError = null;
    state.licenseSuccess = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
