import { OrganisationService } from '@/services/organisation.service.js';

const state = {
  organisations: null,
  selectedOrganisation: null,
  error: null,
  success: null
};

const getters = {
  organisations: state => state.organisations,
  selectedOrganisation: state => state.selectedOrganisation,
  organisationError: state => state.error,
  organisationSuccess: state => state.success
};

const actions = {
  async fetchOrganisations({ commit }) {
    try {
      const response = await OrganisationService.getOrganisations();
      commit('SET_ORGANISATIONS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching organisations');
    }
  },
  async createOrganisation({ commit }, data) {
    try {
      await OrganisationService.createOrganisation(data);
      commit('SET_SUCCESS', 'Organisation created successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating organisation');
    }
  },
  async updateOrganisation({ commit }, { id, data }) {
    try {
      await OrganisationService.updateOrganisation(id, data);
      commit('SET_SUCCESS', 'Organisation updated successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating organisation');
    }
  },
  async deleteOrganisation({ commit }, id) {
    try {
      await OrganisationService.deleteOrganisation(id);
      commit('SET_SUCCESS', 'Organisation deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting organisation');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_ORGANISATIONS: (state, organisations) => {
    state.organisations = organisations;
  },
  SET_SELECTED_ORGANISATION: (state, organisation) => {
    state.selectedOrganisation = organisation;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_SUCCESS: (state, success) => {
    state.success = success;
  },
  clearOrganisations(state) {
    state.organisations = null;
    state.selectedOrganisation = null;
    state.organisationError = null;
    state.organisationSuccess = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
