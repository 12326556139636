import http from '@/http.js';

export class SurveyService {
  static findQRCode(id) {
    return http.get(`/api/surveys/${id}/code`);
  }

  static acceptTerms(id) {
    return http.put(`/api/surveys/${id}/accept-terms`);
  }

  static getSurveys(entry, data) {
    return http.post(`/api/surveys/${entry}/entry`, JSON.stringify(data));
  }

  static getNextQuestion(id, data) {
    return http.post(`/api/surveys/${id}/next-question`, JSON.stringify(data));
  }

  static getQuestionBySequence(id, data) {
    return http.post(`/api/surveys/${id}/question-by-sequence`, JSON.stringify(data));
  }

  static setResponse(id, data) {
    return http.post(`/api/surveys/${id}/set-response`, JSON.stringify(data));
  }

  static getLanguages() {
    return http.get('/api/surveys/languages');
  }
}
