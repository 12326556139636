import { UserService } from '@/services/user.service.js';

const state = {
  users: null,
  selectedUser: null,
  error: null,
  success: null
};

const getters = {
  users: state => state.users,
  selectedUser: state => state.selectedUser,
  userError: state => state.error,
  userSuccess: state => state.success
};

const actions = {
  async fetchUsers({ commit }) {
    try {
      const response = await UserService.getUsers();
      commit('SET_USERS', response.data);
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while fetching users');
    }
  },
  async createUser({ commit }, data) {
    try {
      await UserService.createUser(data);
      commit('SET_SUCCESS', 'User created successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while creating user');
    }
  },
  async updateUser({ commit }, { id, data }) {
    try {
      await UserService.updateUser(id, data);
      commit('SET_SUCCESS', 'User updated successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while updating user');
    }
  },
  async deleteUser({ commit }, id) {
    try {
      await UserService.deleteUser(id);
      commit('SET_SUCCESS', 'User deleted successfully');
    } catch (error) {
      commit('SET_ERROR', 'An error occurred while deleting user');
    }
  },
  removeError({ commit }) {
    commit('SET_ERROR', null);
  },
  removeSuccess({ commit }) {
    commit('SET_SUCCESS', null);
  }
};

const mutations = {
  SET_USERS: (state, users) => {
    state.users = users;
  },
  SET_SELECTED_USER: (state, user) => {
    state.selectedUser = user;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
  },
  SET_SUCCESS: (state, success) => {
    state.success = success;
  },
  clearUsers(state) {
    state.users = null;
    state.selectedUser = null;
    state.userError = null;
    state.userSuccess = null;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
