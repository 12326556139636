import { createStore } from 'vuex'
import auth from './modules/auth'
import organisation from './modules/organisation'
import user from './modules/user'
import license from './modules/license'
import qr_codes from './modules/qr_codes'
import survey from './modules/survey'

export default createStore({
  modules: {
    auth,
    organisation,
    user,
    license,
    qr_codes,
    survey
  }
})
